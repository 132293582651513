import React, { useContext } from 'react';
import { Card } from '@material-tailwind/react';
import { ServicesCartContext } from 'context/ServicesCartContextProvider';

const ServiceItemsTotal = () => {
  const { cart } = useContext(ServicesCartContext);

  const getTotalPrice = cart.reduce(
    (total, item) => total + (item.price * item.quantity || 0),
    0
  );

  return (
    <>
      <Card className="flex items-center justify-between p-4 py-0">
        <div className="container my-4">
          {cart.length === 0 ? (
            <div>No services requested</div>
          ) : (
            <>
              <div>
                {cart.map((item) => (
                  <p key={item.id}>
                    {item.label} - €{(item.price * item.quantity).toFixed(2)}
                  </p>
                ))}
              </div>
              <h4 className="mt-3 font-bold">
                Total: €{getTotalPrice.toFixed(2)}
              </h4>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default ServiceItemsTotal;
