import { differenceInHours, format } from 'date-fns';
import { useRouter } from 'next/router';
import { EventInput, EventInputShortTerm } from 'pages/api/calendar/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faPlaneArrival,
  faPlaneDeparture
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Textarea } from '@material-tailwind/react';
import DateInput from 'components/DateInput';
import DateInputs from 'components/DateInputs';
import EventTypeInput from 'components/EventTypeInput';
import MinimumTimeWarning from 'components/MinimumTimeWarning';
import ParkingFormRenderer from 'components/ParkingFormRenderer/ParkingFormRenderer';
import ParkingTypeInput from 'components/ParkingTypeInput';
import ReviewTable from 'components/ReviewTable';
import ServiceItemsTotal from 'components/Service/ServiceItemsTotal/ServiceItemsTotal';
import Services from 'components/Service/Services';
import Stripe from 'components/Stripe/Stripe';
import UserDetailsInput from 'components/UserDetailsInput';
import { useAuthContext } from 'context/AuthContextProvider';
import { useServiceContext } from 'context/ServicesCartContextProvider';
import { LOCALE, dateOptions } from 'lib/constants';
import { db } from 'lib/firebase';
import { ShortTermBookingFormValues } from 'lib/types';
import {
  calculatePrice,
  getShortTermCart,
  toDateTimeZoned,
  toISOStringTimeZoned
} from 'lib/utils';

interface Props {
  defaultValues: Record<string, string>;
  reset: () => void;
}

const MIN_TIME_ALLOWED_BEFORE_BOOKING = 12;

const ShortTermForm = ({ reset, defaultValues }: Props) => {
  const router = useRouter();
  const { setCart } = useServiceContext();
  const user = useAuthContext();
  const [step, setStep] = useState(0);
  const [pricing, setPricing] = useState({
    basePrice: 0,
    perDayPrice: 0,
    dropOffFeePrice: 0
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<ShortTermBookingFormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: user?.email ?? '',
      registrationNumber: '',
      flightNumber: '',
      startDate: null,
      endDate: null,
      parkingType: null,
      specialRequests: '',
      eventType: 'both',
      ...defaultValues
    }
  });
  const { register, handleSubmit, watch, setValue } = form;
  const { isValid } = form.formState;

  const {
    endDate,
    startDate,
    parkingType,
    firstName,
    lastName,
    email,
    registrationNumber,
    flightNumber,
    eventType
  } = watch();

  const isBeforeMinBookingTime = !!(
    startDate &&
    differenceInHours(startDate, toDateTimeZoned(new Date())) <
      MIN_TIME_ALLOWED_BEFORE_BOOKING
  );

  useEffect(() => {
    setCart([]);
  }, [setCart]);

  const isInvalidDate =
    startDate === null ||
    endDate === null ||
    endDate?.getTime() - startDate?.getTime() < 0;

  useEffect(() => {
    (async () => {
      if (!parkingType) return;

      const data = await fetch('/api/services/get-pricing', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type: parkingType })
      }).then((r) => r.json());

      setPricing(data);
    })();
  }, [parkingType]);

  const onSubmit = async (formValues: ShortTermBookingFormValues) => {
    try {
      setIsSubmitting(true);
      const userRef = doc(db, 'users', user!.uid);

      const {
        startDate,
        endDate,
        flightNumber,
        specialRequests,
        ...recentBookingDetails
      } = formValues;

      await updateDoc(userRef, {
        registrationNumbers: arrayUnion(formValues.registrationNumber),
        recentBookingDetails
      });

      const response = await fetch(
        `/api/calendar/${
          eventType === 'both' ? 'add-event' : 'add-drop-off-event'
        }`,
        {
          method: 'POST',
          body: JSON.stringify({
            formValues: {
              ...formValues,
              bookingType: 'short',
              phoneNumber: user?.phoneNumber || formValues.phoneNumber
            },
            userId: user?.uid ?? '',
            startDateISO: toISOStringTimeZoned(startDate!),
            ...(endDate && { endDateISO: toISOStringTimeZoned(endDate!) })
          } as EventInput)
        }
      ).then((r) => r.json());

      const { bookingId } = await response;

      if (!bookingId) {
        throw new Error('Failed');
      }

      if (typeof bookingId === 'string') {
        router.push(`/booking/short/${bookingId}`);
      }
    } catch (err) {
      setErrorMessage(
        'Something went wrong while creating your booking, please try again.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitWithPayment = async (
    formValues: ShortTermBookingFormValues
  ) => {
    const userRef = doc(db, 'users', user!.uid);

    const {
      endDate,
      startDate,
      flightNumber,
      specialRequests,
      ...recentBookingDetails
    } = formValues;

    updateDoc(userRef, {
      registrationNumbers: arrayUnion(formValues.registrationNumber),
      recentBookingDetails
    });

    return {
      formValues: {
        ...formValues,
        bookingType: 'short',
        eventType: formValues.eventType,
        phoneNumber: user?.phoneNumber || formValues.phoneNumber
      },
      userId: user?.uid,
      startDateISO: toISOStringTimeZoned(startDate!),
      ...(endDate && { endDateISO: toISOStringTimeZoned(endDate!) })
    } as EventInputShortTerm;
  };

  const steps = [
    {
      label: 'Make a reservation for',
      render: (
        <EventTypeInput
          disabledEvents={['pick-up']}
          form={form}
          onChange={(eventType) =>
            eventType === 'drop-off' && setValue('flightNumber', '')
          }
        />
      )
    },
    {
      label: 'How would you like your car parked?',
      disableNext: parkingType === null,
      render: (
        <>
          <div className="grid gap-2 p-4 bg-red-500 text-white mb-4 rounded-lg">
            <p>
              <b>Important information</b>
            </p>
            <p>
              We do not offer “Meet-and-Greet service”. Key drop and pick up is
              carried out by our key deposit system. Between 08:00-20:00 on pick
              up day the fee for the ticket issued by the airport is between
              3.95€ and 10€. For late night arrivals vehicles are parked in the
              airport parking garage at 20.00, therefore the ticket price varies
              between 3.95€ and 23€ depending on arrival time.
            </p>
            <p>(Does not apply for Motorhome)</p>
            <p>Thank you!</p>
          </div>
          <ParkingTypeInput {...form} />
        </>
      )
    },
    {
      label:
        'Schedule the date and time of vehicle pick up and drop off (We only accept bookings 12 hours ahead!)',
      disableNext:
        eventType === 'drop-off' ? startDate === null : isInvalidDate,
      onNextClick: () => {
        if (eventType === 'drop-off') return;
        if (!startDate || !endDate) return;

        setCart(getShortTermCart(startDate, endDate, parkingType, pricing));
      },
      render: (
        <>
          {eventType === 'both' ? (
            <DateInputs form={form} />
          ) : (
            <DateInput title="Start date" name="startDate" form={form} />
          )}
          {isBeforeMinBookingTime ? (
            <MinimumTimeWarning
              hours={String(MIN_TIME_ALLOWED_BEFORE_BOOKING)}
            />
          ) : (
            <>
              {eventType !== 'drop-off' && (
                <div className="py-4 text-lg">
                  Total price:{' '}
                  <span className="font-bold">
                    {
                      calculatePrice({
                        ...pricing,
                        startDate: startDate?.toISOString(),
                        endDate: endDate?.toISOString()
                      }).totalPrice
                    }
                    €
                  </span>
                </div>
              )}
              {eventType !== 'drop-off' && startDate && endDate && (
                <div className="mt-16 grid animate-fade-in grid-rows-2 gap-8">
                  <div className="grid gap-2">
                    <div className="font-bold">Drop off</div>
                    <div className="flex items-center gap-4">
                      <FontAwesomeIcon
                        width={24}
                        size="lg"
                        icon={faPlaneDeparture}
                      />
                      You will leave your vehicle before your departure
                    </div>
                    <div className="flex items-center gap-4">
                      <FontAwesomeIcon width={24} size="lg" icon={faClock} />
                      {format(startDate, "MMMM dd yyyy 'at' HH:mm")}
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <div className="font-bold">Pick up</div>
                    <div className="flex items-center gap-4">
                      <FontAwesomeIcon
                        width={24}
                        fontVariant=""
                        size="lg"
                        icon={faPlaneArrival}
                      />
                      You will arrive at the airport to retrieve your vehicle
                    </div>
                    <div className="flex items-center gap-4">
                      <FontAwesomeIcon width={24} size="lg" icon={faClock} />
                      {format(endDate, "MMMM dd yyyy 'at' HH:mm")}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )
    },
    {
      label: 'Enter your details',
      disableNext: !isValid,
      render: <UserDetailsInput form={form} />
    },
    eventType === 'drop-off'
      ? undefined
      : {
          label: 'Select extra services',
          disableNext: !isValid,
          render: <Services />
        },
    {
      label: 'Review',
      render: (
        <div>
          <ReviewTable
            items={[
              {
                title: 'Person',
                children: [
                  {
                    label: 'Name',
                    value: `${firstName} ${lastName}`
                  },
                  {
                    label: 'Email',
                    value: email
                  }
                ]
              },
              {
                title: 'Flight and vehicle',
                children: [
                  {
                    label: 'Flight number',
                    value: flightNumber
                  },
                  {
                    label: 'Registration number',
                    value: registrationNumber
                  }
                ]
              },
              {
                title: 'Date and time',
                children: [
                  {
                    label: 'Drop off',
                    value: startDate?.toLocaleTimeString(LOCALE, dateOptions)
                  },
                  {
                    label: 'Pick up',
                    value: endDate?.toLocaleTimeString(LOCALE, dateOptions)
                  }
                ]
              },
              {
                title: 'Parking',
                children: [
                  {
                    label: 'Parking type',
                    value: parkingType?.toUpperCase(),
                    valueClassName: 'capitalize'
                  }
                ]
              }
            ]}
          />
          {eventType === 'both' && (
            <div>
              <div className="text-xl mt-8">Services</div>
              <div className="grid">
                <ServiceItemsTotal />
              </div>
            </div>
          )}
          <div className="mt-4">
            <Textarea
              label="Special requests"
              {...register('specialRequests')}
            />
          </div>
        </div>
      )
    },
    eventType === 'drop-off'
      ? undefined
      : {
          label: 'Payment',
          render: (
            <Stripe
              onSubmit={() => onSubmitWithPayment(form.getValues())}
              onComplete={(bookingId) => {
                router.push(`/booking/short/${bookingId}`);
              }}
            />
          )
        }
  ].filter(Boolean);

  const currentStep = steps[step];

  return (
    <>
      <ParkingFormRenderer
        {...{
          currentStep,
          stepIndex: step,
          steps,
          errorMessage,
          isSubmitting
        }}
        title="Short Term Parking"
        onBackClick={() => {
          if (step === 0) {
            reset();
          } else {
            setStep((s) => s - 1);
          }
        }}
        onNextClick={() => {
          setStep((s) => s + 1);
          currentStep?.onNextClick?.();
        }}
        onSubmit={handleSubmit(onSubmit)}
        disableNext={step === 2 && isBeforeMinBookingTime}
      />
    </>
  );
};

export default ShortTermForm;
